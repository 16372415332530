.filterBar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.filterBar > div {
  margin: 0.2rem;
  width: 15%;
}

.result-artist {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.result-table-div {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.result-table {
  width: 20%;
  font-size: 1.3rem;
  border: 1px solid black;
}

.number {
  font-family: "Oswald", sans-serif;
  font-style: normal;
}

.excel-button {
  display: flex;
  width: 100%;
  justify-content: center;
}

.excel-button button {
  margin: 1rem;
}

.excel-button button:hover {
  background-color: black;
  color: white;
}

.loading-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100hv;
}

.flex-edit {
  display: flex;
  padding: 1rem;
  flex-direction: row;
  justify-content: space-between;
}

.edit-menu {
  font-size: 1rem;
}

.edit-menu button {
  margin: 0.2rem;
}

.css-1xc3v61-indicatorContainer {
  padding: 0;
}

.commentaire-section {
  width: 50rem;
  text-align: left;
}

.col-test th,
.col-test td {
  text-align: center;
}

.logo-structure {
  width: 20px;
  height: 20px;
}

.edit-button {
  justify-self: end;
}

.button-section {
  display: flex;
}
.button-section button {
  margin: 0.2rem;
}

.artist-block {
  display: flex;
}

.profil-pic {
  border-radius: 50%;
  height: 7rem;
  width: 7rem;
  margin-right: 2rem;
}

.small {
  height: 3rem;
  width: 3rem;
  margin-right: 1rem;
}

.artist-block-name {
  margin-top: 2rem;
}

.artist-block-name-small {
  margin-top: 0.4rem;
}

.cursor {
  cursor: pointer;
}

.checkbox {
  margin: 20px;
  width: 20px; /* Largeur de la checkbox */
  height: 20px; /* Hauteur de la checkbox */
}
